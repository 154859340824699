import { define, html } from "hybrids";

interface VWebsiteContainer {}

export default define<VWebsiteContainer>({
  tag: "v-website-container",
  render: () => html`
    <div id="container">
      <slot></slot>
    </div>
  `.css`
    :host { 
      display: block;
      background: white;
    }

    :host(:empty) {
      display: none;
    }

    #container {
      max-width: 1120px;
      margin: 0 auto;
      padding: 0 var(--v-ui-layout-spacing);
    }
  `,
});
