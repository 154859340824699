import { define, html } from "hybrids";

interface VWebsiteBadge {
  color: string;
}

export default define<VWebsiteBadge>({
  tag: "v-website-badge",
  color: "secondary",
  render: ({ color }) => html`
    <v-ui-text variant="caption">
      <slot></slot>
    </v-ui-text>
  `.css`
    :host {
      display: inline-block;
      background: var(--v-ui-color-${color});
      padding: 3px 8px;
      border-radius: 6px;
      --v-ui-color-primary: #d62252;
    }

    v-ui-text {
      color: white;
    }
  `,
});
