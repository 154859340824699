import { store, Model } from "hybrids";
import { get, post } from "~/utils/api";

import User from "./User";

interface Notification {
  id: string;
  key: string;
  user: User;
  content: string;
  createdAt: string;
  read: boolean;
}

const Notification: Model<Notification> = {
  id: true,
  key: "",
  user: User,
  content: "",
  createdAt: "",
  read: false,
  [store.connect]: {
    cache: 1000 * 60, // a minute
    offline: 1000 * 60 * 5, // 5 minutes
    set: (id, values) => values,
    list(params) {
      return get("/notifications", params);
    },
  },
};

export default Notification;

export function markAsRead(notification: Notification) {
  post("/notifications/:id/mark_as_read", null, notification.id).catch(() => {
    store.set(notification, { read: false });
  });

  return store.set(notification, { read: true });
}

export function markAllAsRead() {
  return post("/notifications/mark_all_as_read").then(() => {
    store.clear([Notification], false);
  });
}
