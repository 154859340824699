import { define, html } from "hybrids";
import { post } from "~/utils/api";

function close(host: HTMLElement) {
  post("/accept_cookies");
  host.parentElement?.removeChild(host);
}

export default define({
  tag: "v-website-gdpr-banner",
  render: () => html`
    <v-ui-flexbox flow="row" layout="center" gap="medium">
      <v-ui-text variant="caption">
        We use cookies to improve your browsing experience on our website. By
        closing this banner or interacting with our site, you acknowledge and
        agree to our <a href="/pages/privacy">Privacy Policy</a>.
      </v-ui-text>
      <v-ui-button name="Got it!" onclick="${close}"></v-ui-button>
    </v-ui-flexbox>
  `.css`
    :host {
      z-index: var(--v-ui-z-index-banner);
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      padding: var(--v-ui-layout-spacing);
      box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.05);
    }

    v-ui-button {
      flex-basis: 140px;
    }
  `,
});
