import { define, html } from "hybrids";
import { share, supportsNative } from "~/utils/share";

interface VWebsiteShareButton {
  url: "";
  name: "";
}

export default define<VWebsiteShareButton>({
  tag: "v-website-share-button",
  name: "",
  url: "",
  render: ({ url }) => (supportsNative
    ? html`<v-website-social-button icon="share" onclick="${share}">
        Share
      </v-website-social-button>`
    : html`
        <v-website-dropdown>
          <v-website-social-button icon="share">
            Share
          </v-website-social-button>
          <v-website-share-box id="content" slot="dropdown" url="${url}">
          </v-website-share-box>
        </v-website-dropdown>
      `
  ).css`
    :host {
      display: block;
    }

    input {
      min-width: 240px;
    }

    #content {
      padding: var(--v-ui-layout-spacing);
    }
  `,
});
