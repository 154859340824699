import { define, html } from "hybrids";

interface VWebsiteSocialButton {
  icon: string;
  iconText: string;
  href: string;
}

export default define<VWebsiteSocialButton>({
  tag: "v-website-social-button",
  icon: "",
  iconText: "",
  href: "",
  render: ({ icon, iconText, href }) => html`
    <v-ui-button
      id="button"
      variant="transparent"
      color="text-secondary"
      href="${href}"
    >
      <v-ui-flexbox layout="center">
        <v-ui-flexbox id="icon" flow="row" layout="start center">
          <v-ui-icon name="${icon}"></v-ui-icon>
          ${iconText &&
          html`<v-ui-text
            id="icon-text"
            variant="button"
            color="text-secondary"
            weight="semibold"
          >
            ${iconText}
          </v-ui-text>`}
        </v-ui-flexbox>
        <v-ui-text id="name" variant="caption"><slot></slot></v-ui-text>
      </v-ui-flexbox>
    </v-ui-button>
  `.css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      --v-ui-icon-width: 20px;
      --v-ui-icon-height: 20px;
    }

    v-ui-button { min-width: 34px; }
    #icon { height: 32px; }
    #icon v-ui-text { margin-left: 2px; }
    #name { display: none }

    @media (min-width: 768px) {
      #icon { height: 20px; flex-flow: row-reverse; }
      #icon v-ui-text { margin-left: 0; margin-right: 2px; }
      #name { display: block; margin-top: 4px }
    }
  `,
});
