import { define, html } from "hybrids";
import { shareSocial, copy, ShareHost } from "~/utils/share";

interface VWebstieShareBox extends ShareHost {
  border: boolean;
}

export default define<VWebstieShareBox>({
  url: "",
  name: "",
  copied: false,
  tag: "v-website-share-box",
  border: false,
  render: ({ url, copied }) => html`
    <slot></slot>
    <v-ui-flexbox gap="small">
      <v-ui-text variant="button" weight="semibold">Link:</v-ui-text>
      <v-ui-flexbox flow="column" layout="start stretch">
        <v-ui-input>
          <input value="${url}" readonly />
        </v-ui-input>
        <v-ui-flexbox flow="row" layout="end">
          <v-ui-button type="transparent" onclick="${copy}">
            <v-ui-icon name="link" size="small" color="link"></v-ui-icon>
            ${copied ? "Copied" : "Copy link"}
          </v-ui-button>
        </v-ui-flexbox>
      </v-ui-flexbox>
      <v-ui-text variant="button" weight="semibold">
        Social networks:
      </v-ui-text>
      <v-ui-flexbox flow="row" gap="small">
        <v-ui-button
          onclick="${shareSocial("facebook")}"
          type="round"
          icon="facebook"
        ></v-ui-button>
        <v-ui-button
          onclick="${shareSocial("twitter")}"
          type="round"
          icon="twitter"
        ></v-ui-button>
        <v-ui-button
          href="mailto:?subject=${name}&body=${url}"
          type="round"
          icon="mail"
        ></v-ui-button>
        <v-ui-button
          onclick="${shareSocial("linkedin")}"
          type="round"
          icon="linkedin"
        ></v-ui-button>
        <v-ui-button
          onclick="${shareSocial("telegram")}"
          type="round"
          icon="telegram"
        ></v-ui-button>
        <v-ui-button
          onclick="${shareSocial("whatsapp")}"
          type="round"
          icon="whatsapp"
        ></v-ui-button>
      </v-ui-flexbox>
    </v-ui-flexbox>
  `.css`
    :host {
      display: block;
      --v-ui-icon-width: 32px;
      --v-ui-icon-height: 32px;
    }

    :host([border]) {
      border: 1px solid #EAEAEA;
      border-radius: 8px;
      padding: calc(var(--v-ui-layout-spacing) * 2);
    }
  `,
});
