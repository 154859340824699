import {define, html, router, store} from "hybrids";
import Tag from "~/stores/Tag";
import TagMain from "~/modules/home/views/TagMain";
import layout from "~/factories/layout";


interface VEmptyCollectionList {
  message: string;
  featuredTags: Tag[];
}

export default define<VEmptyCollectionList>({
  tag: "v-empty-video-list",
  message: "",
  featuredTags: store([Tag], { id: () => ({ featured: true }) }),
  content: ({ message, featuredTags }) => html`
    <v-home-section>
      <v-ui-text color="text-secondary">
        ${message}
      </v-ui-text>
    </v-home-section>
    
    <v-home-section>
      <v-ui-text variant="h2" weight="black"
        >Explore</v-ui-text
        >
    </v-home-section>
    
    ${store.ready(featuredTags) &&
    !!featuredTags.length && html`
      <v-home-section>
        <v-ui-flexbox layout="center" flow="row wrap" gap="small">
          ${featuredTags.map((featuredTag) => html`
            <v-ui-button
              name="${featuredTag.name}"
              type="cta"
              color="white"
              href="${router.url(TagMain, { tagObject: featuredTag })}"
            ></v-ui-button>`
          )}
        </v-ui-flexbox>
      </v-home-section>`}`
});
