import { Descriptor } from "hybrids";

let activeTarget: HTMLElement | null;
let scrollRestoration: ScrollRestoration;

const clearMap = new WeakMap();

function push(host: HTMLElement) {
  if (!activeTarget) {
    scrollRestoration = window.history.scrollRestoration;
    window.history.scrollRestoration = "manual";
    window.history.pushState(window.history.state, "");
  }

  activeTarget = host;
}

function clearHistory() {
  window.history.back();
  window.history.scrollRestoration = scrollRestoration;
  activeTarget = null;
}

function pull(host: HTMLElement) {
  if (activeTarget === host) {
    setTimeout(() => {
      if (activeTarget === host) {
        clearHistory();
      }
    }, 0);
  }
}

window.addEventListener("popstate", () => {
  if (activeTarget) {
    const clear = clearMap.get(activeTarget);
    clear();
    activeTarget = null;
  }
});

document.addEventListener(
  "mouseup",
  (event: Event) => {
    if (activeTarget) {
      const path = event.composedPath();
      const clear = clearMap.get(activeTarget);

      if (!path.includes(activeTarget)) {
        clear();
      } else if (path.some((el) => el instanceof HTMLAnchorElement)) {
        clear();
        clearHistory();
      }
    }
  },
  {
    passive: true,
  }
);

document.addEventListener(
  "touchmove",
  (event: Event) => {
    if (activeTarget) {
      const path = event.composedPath();
      const clear = clearMap.get(activeTarget);

      if (!path.includes(activeTarget)) {
        clear();
      }
    }
  },
  {
    passive: true,
  }
);

export default function menu<E extends object>(
  observe?: Descriptor<E, boolean>["observe"]
): Descriptor<E, boolean> {
  return {
    value: false,
    connect: (host, key) => {
      clearMap.set(host, () => {
        host[key] = false;
      });

      return () => {
        pull(host);
      };
    },
    observe: (host, value, lastValue) => {
      if (value) {
        push(host);
      } else if (lastValue) {
        pull(host);
      }

      if (observe) {
        observe(host, value, lastValue);
      }
    },
  };
}
