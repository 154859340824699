import { define, html } from "hybrids";

interface VAppBadges {
  flow: "row" | "column";
  appStoreIconName: string;
  playStoreIconName: string;
  desktopVisibility: string;
}

export default define<VAppBadges>({
  tag: "v-website-app-badges",
  flow: "column",
  appStoreIconName: "appstore",
  playStoreIconName: "playstore",
  desktopVisibility: "none",
  render: ({ flow, appStoreIconName, playStoreIconName, desktopVisibility }) => html`
    <v-ui-flexbox id="badges" layout="center" flow=${flow} gap="small">
      <v-ui-link
        href="https://apps.apple.com/us/app/viewcy/id1658393524"
        target="_blank"
      >
        <v-ui-icon name=${appStoreIconName}>
        </v-ui-icon>
      </v-ui-link>

      <v-ui-link
        href="https://play.google.com/store/apps/details?id=com.viewcy.prod"
        target="_blank"
      >
        <v-ui-icon name=${playStoreIconName}>
        </v-ui-icon>
      </v-ui-link>
    </v-ui-flexbox>
  `.css`
    :host([flow="column"]) v-ui-link {
      margin: calc(var(--v-ui-layout-spacing) / 2) 0;
    }
    
    :host([flow="row"]) v-ui-link {
      margin: 0 calc(var(--v-ui-layout-spacing) / 4);
    }
    
    @media (min-width: 931px) {
      :host {
        display: ${desktopVisibility};
      }
    }
  `,
});
