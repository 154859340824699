import { html, define, Component } from "hybrids";

interface VWebsiteUser {
  avatar: string;
}

export default define<VWebsiteUser>({
  tag: "v-website-user",
  avatar: "",
  render: ({ avatar }) => html`
    <v-ui-avatar src="${avatar}"></v-ui-avatar>
    <v-ui-text><slot></slot></v-ui-text>
  `.css`
    :host {
      display: flex;
      align-items: center;
    }

    v-ui-avatar {
      margin-right: 4px;
    }
  `,
});
