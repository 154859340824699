import { html, define, Component } from "hybrids";

export default define({
  tag: "v-website-footer",
  render: () => html`
    <div class="footer-column">
      <div id="logo">
        <v-ui-icon name="logo" color=""></v-ui-icon>
        <v-ui-icon name="logofullwhite" color=""></v-ui-icon>

        <v-ui-text color="text-secondary" variant="h4" weight="normal" ygutter="1">
          Creator tools and funding
          <br />
          For musicians, by musicians
        </v-ui-text>
      </div>
    </div>

    <div class="footer-column">
      <v-ui-text class="header" color="text-secondary" uppercase>
        About
      </v-ui-text>
      <slot name="about"></slot>
    </div>

    <div class="footer-column">
      <v-ui-text class="header" color="text-secondary" uppercase>
        Community
      </v-ui-text>
      <slot name="community"></slot>
    </div>

    <div class="footer-column">
      <v-ui-text class="header" color="text-secondary" uppercase>
        Help
      </v-ui-text>
      <slot name="help"></slot>
    </div>

    <div class="footer-column">
      <v-ui-text class="header" color="text-secondary" uppercase>
        Legal
      </v-ui-text>
      <slot name="legal"></slot>
    </div>

    <div class="footer-column">
      <v-ui-text class="header" color="text-secondary" uppercase>
        Follow
      </v-ui-text>
      <div id="follow">
        <v-ui-flexbox flow="row">
          <slot name="follow"></slot>
        </v-ui-flexbox>
      </div>
      <slot></slot>
    </div>

    <div id="apps-column">
        <div id="apps">
          <slot name="apps"></slot>
        </div>
    </div>

    <div id="footer">
      <v-ui-text color="text-secondary" variant="caption" slot="footer">
        <slot name="footer"></slot>
      </v-ui-text>
    </div>
  `.css`
    :host {
      display: grid;
      justify-items: center;
      background: black;
      padding: 44px 44px 112px;
      color: white;
      font-size: var(--v-ui-font-size-default);
      text-align: center;
      --v-ui-color-link: white;
      --v-ui-color-text-primary: white;
      --v-ui-color-text-secondary: #666;
      --v-ui-font-size-caption: 14px;
    }

    :host ::slotted(a) {
      user-select: none;
      margin: 10px 0;
    }

    #logo {
      margin-bottom: var(--v-ui-layout-spacing);
    }
    
    #follow {
      --v-ui-icon-color: #666;
    }
    
    #follow v-ui-flexbox {
      margin: 0 -8px;
    }

    #follow ::slotted(*) {
      margin: 20px 8px;
    }

    .footer-column {
      display: grid;
      gap: 0;
    }
    
    #apps {
      display: grid;
      grid-template-rows: auto auto;
    }
    
    #apps ::slotted(a) {
      margin: 0;
    }
    
    @media (max-width: 991px) {
      #logo v-ui-icon[name="logo"] { display: none; }
      #footer {
        padding-top: 36px;
      }
    }

    @media (max-width: 768px) {
      .header { display: none; }
      #apps {
        gap: 20px;
      }
    }

    @media (min-width: 768px) {
      :host {
        grid-gap: 25px;
        grid-template-columns: repeat(7, auto);
        justify-items: flex-start;
        justify-content: center;
        padding: 44px 16px;
        --v-ui-font-size-default: 13px;
        text-align: left;
      }

      :host ::slotted(a) {
        margin: 0;
      }

      #follow ::slotted(*) {
        margin: 0px 8px;
      }

      #logo {
        grid-row: 1/4;
        display: grid;
        justify-self: stretch;
        align-content: flex-start;
        margin: 0;
      }

      #logo v-ui-text,
      #logo v-ui-icon[name="logofullwhite"] { display: none; }

      #footer {
        padding-top: 36px;
        justify-self: center;
        grid-column: 1/6;
        grid-row: 2;
      }

      .footer-column {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .header {
        font-size: 12px;
      }
      
      #apps {
        gap: 10px;
      }
    }
    
    @media (min-width: 931px) {
      :host {
        grid-gap: 80px;
        grid-template-columns: repeat(6, auto);
      }
      
      #apps-column {
        display: none;
      }
    }
   
  `,
});
