import { html, define } from "hybrids";
import menu from "~/factories/menu";

interface VWebsiteDropdown {
  arrow: boolean;
  dot: boolean;
  open: boolean;
}

function toggleOpen(host: VWebsiteDropdown) {
  host.open = !host.open;
}

export default define<VWebsiteDropdown>({
  tag: "v-website-dropdown",
  arrow: false,
  dot: false,
  open: menu(),
  render: ({ arrow, open, dot, title }) =>
    html`
      <v-ui-button
        variant="inline"
        onclick="${toggleOpen}"
        dot="${dot}"
        title="${title}"
      >
        <slot></slot>
        ${arrow && html`<v-ui-icon name="arrowdown" size="small"></v-ui-icon>`}
      </v-ui-button>
      <section id="dropdown" class="${{ open }}">
        <slot name="dropdown"></slot>
      </section>
    `.css`
      :host {
        display: grid;
        place-items: center;
        position: relative;
      }

      v-ui-button {
        height: auto;
      }

      v-ui-icon {
        margin-left: 8px;
        --v-ui-icon-color: var(--v-ui-color-text-primary);
      }

      #dropdown {
        visibility: hidden;
        position: absolute;
        top: calc(100% + 8px);
        right: var(--v-website-dropdown-right, 0);
        background: var(--v-ui-nav-dropdown-background-color);
        color: var(--v-ui-nav-text-color);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border: 1px solid var(--v-ui-nav-dropdown-border-color);
        box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 200ms, visibility 0s 200ms, transform 200ms;
      }

      #dropdown.open {
        z-index: 1;
        visibility: visible;
        opacity: 1;
        transform: none;
        transition: opacity 100ms, transform 100ms;
      }
    `,
});
