import { define, html } from "hybrids";

interface VWebsiteFormLayout {
  backUrl: string;
}

export default define<VWebsiteFormLayout>({
  tag: "v-website-form-layout",
  backUrl: "",
  render: ({ backUrl }) => html`
    <section id="container">
      ${backUrl &&
      html`<v-ui-flexbox id="nav" flow="row">
        <v-ui-button
          href="${backUrl}"
          icon="back"
          name="Back"
          color="background"
        ></v-ui-button
      ></v-ui-flexbox>`}
      <v-ui-text variant="h3" weight="bold">
        <slot name="title"></slot>
      </v-ui-text>
      <v-ui-text><slot name="subtitle"></slot></v-ui-text>
      <div id="content">
        <slot></slot>
      </div>
    </section>
  `.css`
    :host {
      display: block;
      padding: calc(var(--v-ui-layout-spacing) * 2) var(--v-ui-layout-spacing);
      --v-ui-font-size-default: 14px;
      --v-ui-color-link: var(--v-ui-color-action);
    }

    #container {
      display: flex;
      flex-direction: column;
      min-height: 74vh;
      position: relative;
      max-width: 808px;
      margin: 0 auto;
    }

    #content {
      flex: 1 0;
      display: flex;
      flex-direction: column;
      margin-top: calc(var(--v-ui-layout-spacing) * 2);
    }

    #nav {
      margin: 0 0 var(--v-ui-layout-spacing);
      --v-ui-color-button: var(--v-ui-color-text-primary);
      --v-ui-font-size-button: 14px;
    }

    @media (min-width: 1100px) {
      #nav {
        position: absolute;
        right: 100%;
        margin-right: calc(var(--v-ui-layout-spacing) * 2);
      }
    }
  `,
});
