import { html, define, Component } from "hybrids";

interface VWebsiteHeaderMenu {
  showOrganizations: boolean;
  showBadges: boolean;
}

export default define<VWebsiteHeaderMenu>({
  tag: "v-website-header-menu",
  showOrganizations: false,
  showBadges: false,
  render: ({ showOrganizations, showBadges }) => html`
    <nav id="main"><slot></slot></nav>
    ${showOrganizations && html`<nav id="organizations"><slot name="organizations"></slot></nav>`}
    <nav id="actions"><slot name="actions"></slot></nav>
    ${showBadges && html`<nav id="app-badges"><slot name="badges"></slot></nav>`}
    <nav id="footer"><slot name="footer"></slot></nav>
  `.css`
    :host {
      font-size: 16px;
      --v-ui-font-size-button: 16px;
    }

    nav {
      display: grid;
      padding: 4px;
    }

    ::slotted(a) {
      padding: 10px 0 10px 20px;
    }

    #actions {
      border-top: 1px solid var(--v-ui-nav-dropdown-border-color);
    }

    #organizations {
      border-top: 1px solid var(--v-ui-nav-dropdown-border-color);
    }

    #footer {
      border-top: 1px solid var(--v-ui-nav-dropdown-border-color);
    }
    
    #app-badges {
      border-top: 1px solid var(--v-ui-nav-dropdown-border-color);
    }
    
    @media (min-width: 931px) {
      #app-badges {
        display: none;
      }
    }
  `,
});
