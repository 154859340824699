import { define, html, store } from "hybrids";
import Course from "~/stores/Course";

interface VWebsiteCourseLikeButton {
  defaultValue: number;
  href: string;
  courseId: string;
  course: Course;
  count: number;
  liked: boolean;
}

function toggleLike(host: VWebsiteCourseLikeButton) {
  store
    .set(host.course, {
      likeCount: host.liked ? host.count - 1 : host.count + 1,
    })
    .then(() => {
      host.liked = !host.liked;
    });
}

export default define<VWebsiteCourseLikeButton>({
  tag: "v-website-course-like-button",
  defaultValue: 0,
  href: "",
  courseId: "",
  course: store(Course, { id: "courseId" }),
  count: ({ defaultValue, course }) =>
    store.ready(course) ? course.likeCount : defaultValue,
  liked: false,
  render: ({ href, count, liked }) => html`
    <v-website-social-button
      icon="${liked ? "heartfill" : "heart"}"
      iconText="${count || ""}"
      onclick="${href ? null : toggleLike}"
      href="${href}"
    >
      Like
    </v-website-social-button>
  `.css`
    :host { display: block; }
  `,
});
