import { html, define, Component, store } from "hybrids";
import "@github/time-elements";

import Notification, { markAsRead, markAllAsRead } from "~/stores/Notification";

interface VWebsiteHeaderNotifications {
  page: number;
  pages: Notification[][];
  loadMore: boolean;
  dot: boolean;
}

function setNextPage(host: VWebsiteHeaderNotifications) {
  host.page += 1;
}

const PER_PAGE = 50;

export default define<VWebsiteHeaderNotifications>({
  tag: "v-website-header-notifications",
  page: 1,
  pages: ({ page }) => {
    const result: Notification[][] = [];

    for (let i = 1; i <= page; i += 1) {
      const notifications = store.get([Notification], { page: i });
      result.push(notifications);
    }

    return result;
  },
  loadMore: ({ pages }) => pages[pages.length - 1].length === PER_PAGE,
  dot: ({ pages }) =>
    pages.some(
      (notifications) =>
        store.ready(notifications) && notifications.some((n) => !n.read)
    ),
  render: ({ pages, loadMore, dot }) => html`
    <v-website-dropdown dot=${dot} title="Notifications">
      <v-ui-icon name="notification"></v-ui-icon>
      <section id="notifications" slot="dropdown" lang="en">
        <v-ui-flexbox flow="row" layout="space-between" id="header">
          <v-ui-text weight="semibold">Notifications</v-ui-text>
          ${dot &&
          html`<v-ui-button
            name="Mark all as read"
            variant="inline"
            type="transparent"
            color="secondary"
            onclick="${markAllAsRead}"
          ></v-ui-button>`}
        </v-ui-flexbox>
        <v-ui-flexbox id="list">
          ${pages.map(
            (page) =>
              store.ready(page) &&
              page.map(
                (notification) => html`
                  <div
                    class="notification"
                    onclick="${!notification.read &&
                    (() => markAsRead(notification))}"
                  >
                    <v-ui-flexbox flow="row" class="avatar">
                      ${!notification.read && html`<div class="dot"></div>`}
                      <v-ui-avatar
                        src="${notification.user.avatarThumbnail}"
                      ></v-ui-avatar>
                    </v-ui-flexbox>
                    <v-ui-text innerHTML="${notification.content}"></v-ui-text>
                    <v-ui-text
                      class="time"
                      variant="caption"
                      color="text-secondary"
                    >
                      <relative-time
                        datetime="${notification.createdAt}"
                      ></relative-time>
                    </v-ui-text>
                  </div>
                `
              )
          )}
          ${loadMore &&
          html`<v-ui-button
            id="load-more"
            variant="inline"
            onclick="${setNextPage}"
            name="Load more..."
          ></v-ui-button>`}
        </v-ui-flexbox>
      </section>
    </v-website-dropdown>
  `.css`
      :host {
        display: grid;
      }

      #notifications {
        display: block;
        width: 320px;
        max-width: 90vw;
        font-size: 14px;
      }

      v-website-dropdown {
        --v-ui-button-dot-top: -2px;
        --v-ui-button-dot-right: 0px;
        --v-ui-button-dot-border: 2px solid white;
      }

      #header {
        padding: 16px;
        border-bottom: 1px solid var(--v-ui-nav-dropdown-border-color);
      }

      #list {
        max-height: min(55vh, 520px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      #load-more {
        padding: 8px;
      }

      .notification {
        position: relative;
        display: grid;
        grid: min-content min-content / max-content 1fr;
        grid-gap: 4px 8px;
        padding: 16px 0;
        margin: 0 16px;
        --v-ui-font-size-default: 14px;
        border-bottom: 1px solid var(--v-ui-nav-dropdown-border-color);
      }

      .notification:last-child {
        border: none;
      }

      .notification a {
        color: var(--v-ui-color-secondary);
        font-weight: 600;
        text-decoration: none;
      }

      .notification .avatar {
        margin-top: 4px;
        grid-row: 1/3;
        grid-column: 1;
        --v-ui-avatar-size: 32px;
      }

      .notification .time {
        grid-column: 2;
      }

      .notification .dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin: 12px 8px 0 0;
        background: var(--v-ui-color-text-error);
      }

      @media (max-width: 767px) {
        v-website-dropdown {
          --v-website-dropdown-right: -84px;
        }
      }

      @media (min-width: 768px) {
        #notifications {
          width: 365px;
        }
      }
    `,
});
