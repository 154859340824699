import { define, html } from "hybrids";
import mediaQuery from "~/factories/mediaQuery";

interface VWebsiteCourseLayout {
  isDesktop: boolean;
}

export default define<VWebsiteCourseLayout>({
  tag: "v-website-course-layout",
  isDesktop: mediaQuery("(min-width: 768px)"),
  render: ({ isDesktop }) => html`
    <div id="poster">
      <slot name="poster"></slot>
    </div>
    <section id="name">
      ${!isDesktop &&
      html`
        <v-ui-flexbox layout="end">
          <slot name="share"></slot>
        </v-ui-flexbox>
      `}
      <slot name="name"></slot>
    </section>
    <aside id="aside">
      <v-ui-flexbox
        id="actions"
        flow="${isDesktop ? "column" : "column-reverse"}"
      >
        <slot name="type"></slot>
        <v-ui-flexbox flow="${isDesktop ? "column" : "row"}" grow="1" gap="small">
          <slot name="actions"></slot>
        </v-ui-flexbox>
      </v-ui-flexbox>
      ${isDesktop && html`
        <slot name="share"></slot>`}
      <slot name="aside"></slot>
      ${isDesktop && html`
        <slot name="users"></slot>`}
    </aside>
    <v-ui-text id="content" selectable>
      <slot></slot>
    </v-ui-text>
    ${!isDesktop && html`
      <div id="users">
        <slot name="users"></slot>
      </div>`}
  `.css`
    :host {
      display: grid;
      grid-gap: var(--v-website-course-layout-gap);
      grid-template-rows: repeat(4, min-content) auto;
      padding-bottom: var(--v-website-course-layout-gap);
      min-height: 74vh;

      --v-ui-color-link: var(--v-ui-color-action);
      --v-ui-avatar-size: 32px;
      --v-website-course-layout-gap: 24px;
    }

    #poster ::slotted(*) {
      display: block;
      max-width: 100%;
    }

    #content {
      max-width: 100%;
      --v-ui-line-height: 1.7em;
      hyphens: auto;
      word-break: break-word;
    }

    #content, #aside {
      font-size: 16px;
      --v-ui-font-size-default: 16px;
    }

    #aside slot[name="aside"]::slotted(*),
    #aside slot[name="type"]::slotted(*) {
      --v-ui-font-size-button: 14px;
    }
    
    #aside slot[name="actions"]::slotted(*) {
      --v-ui-font-size-default: 14px;
    }

    #content ::slotted(h1), #content ::slotted(h2) {
      font-size: 20px;
      font-weight: 700;
    }

    #actions ::slotted(*) {
      margin: calc(var(--v-ui-layout-spacing) / 2) 0;
    }

    #actions {
      margin: calc(var(--v-ui-layout-spacing) / -2) 0;
    }

    @media (max-width: 767px) {
      #poster {
        margin: 0 calc(var(--v-ui-layout-spacing) * -1) -16px;
      }

      #actions {
        z-index: 1;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        margin: 0;
        padding: 0 var(--v-ui-layout-spacing);
        border-top: 1px solid var(--v-ui-color-background);
        box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.05);
        font-size: 12px;
        --v-ui-font-size-default: 12px;
        --v-ui-flexbox-gap: 8px;
      }
      
      #aside slot[name="actions"]::slotted(v-ui-flexbox) {
        width: 45%;
        margin-right: 5px;
      }
      #aside slot[name="type"]::slotted(*) {
        visibility: hidden;
        height: 0;
      }
    }

    @media (min-width: 768px) {
      :host {
        margin: var(--v-ui-layout-spacing) 0;
        grid-template-rows: repeat(2, min-content) auto;
        grid-template-columns: 1fr 280px;
        --v-website-course-layout-gap: 24px;
      }

      #poster {
        grid-row: 2 / 3;
      }

      #poster ::slotted(*) {
        display: block;
        border-radius: 8px;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      }

      #poster, #content {
        grid-column: 1 / 2;
      }

      #content {
        padding-bottom: 0;
      }

      #aside {
        grid-row: 2 / 4;
        grid-column: 2 / 3;
        border-top: none;
        padding-top: 0;
      }
      
      #aside slot[name="share"]::slotted(*), #aside slot[name="aside"]::slotted(*) {
        margin-bottom: var(--v-website-course-layout-gap);
      }

      #actions {
        padding-bottom: var(--v-website-course-layout-gap);
      }
    }

    @media (min-width: 1200px) {
      :host {
        margin: calc(2 * var(--v-ui-layout-spacing)) 0;
      }
    }
  `,
});
