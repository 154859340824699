import { html, define, Component } from "hybrids";
import menu from "~/factories/menu";

interface VWebsiteHeader extends HTMLElement {
  search: boolean;
}

function toggleSearch(host: VWebsiteHeader) {
  host.search = !host.search;
}

export default define<VWebsiteHeader>({
  tag: "v-website-header",
  search: menu((host, value) => {
    const input = host.querySelector(
      'input[slot="search"]'
    ) as HTMLInputElement;

    if (input) {
      if (value) {
        const length = input.value.length;
        if (length) {
          input.selectionStart = length;
          input.selectionEnd = length;
        }
      } else {
        input.value = input.getAttribute("value") || "";
      }

      Promise.resolve().then(() => input.focus());
    }
  }),
  render: ({ search }) => html`
    <nav id="logo"><slot name="logo"></slot></nav>
    <nav id="main">
      <v-ui-button variant="inline" onclick="${toggleSearch}" title="Search">
        <v-ui-icon name="search"></v-ui-icon>
      </v-ui-button>
      <slot></slot>
    </nav>
    ${search &&
    html`
      <section flow="row" id="search">
        <v-ui-icon
          class="icon input"
          name="search"
          color="text-secondary"
        ></v-ui-icon>
        <v-ui-input>
          <slot name="search"></slot>
        </v-ui-input>
        <v-ui-button title="close" type="inline" onclick="${toggleSearch}">
          <v-ui-icon name="close" color="text-secondary"></v-ui-icon>
        </v-ui-button>
      </section>
    `}
  `.css`
    :host {
      z-index: var(--v-ui-z-index-dialog);
      position: relative;
      display: grid;
      grid: 60px / max-content 1fr max-content;
      align-items: stretch;
      padding: 0px 16px;
      background: var(--v-ui-nav-background-color);
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid var(--v-ui-nav-border-color);
      font-size: 14px;
      --v-ui-font-size-button: 14px;
      --v-ui-avatar-size: 28px;
      --v-ui-color-primary: #252525;
      --v-ui-color-secondary: #6874de;
    }

    :host([search]) {
      z-index: calc(var(--v-ui-z-index-dialog) + 1);
    }

    :host([search]) #main, :host([search]) #logo {
      display: none;
    }

    #logo {
      display: grid;
      place-items: center;
      --v-ui-icon-height: 36px;
    }

    #main {
      grid-column: 3;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-template-rows: 34px;
      grid-gap: 4px;
      align-self: center;
      align-items: stretch;
    }

    #main v-ui-button, #main ::slotted(*) {
      min-width: 34px;
    }

    #main ::slotted(v-ui-avatar) {
      width: 28px;
    }

    #search {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--v-ui-nav-background-color);
    }

    #search v-ui-icon, #search v-ui-button {
      width: 48px;
    }

    #search v-ui-input {
      flex: 1;
      padding: 0 10px;
      --v-ui-input-border-color: transparent;
    }

    @media (min-width: 768px) {
      :host {
        grid-template-rows: 70px;
        padding: 0 30px;
        font-size: 16px;
      }

      #main {
        grid-gap: 12px;
      }

      #search v-ui-icon, #search v-ui-button {
        width: 80px;
      }
    }
  `,
});
